import React from "react";
import classes from "./Footer.module.css";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const mailToHandler = (e) => {
    e.preventDefault();
    window.location.href = "mailto:robbins.m.brian@gmail.com";
  };
  return (
    <IconContext.Provider value={{ color: "#0e8388", size: "40px" }}>
      <div className={classes.footer_container}>
        <Link
          to="https://www.linkedin.com/in/brian-robbins-1a9253153/"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
          <p className={`san-serif ${classes.link_text}`}>LinkedIn</p>
        </Link>
        <Link
          to="https://github.com/BMRobbins"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub />
          <p className={`san-serif ${classes.link_text}`}>GitHub</p>
        </Link>
        <Link
          to="#"
          className={classes.link}
          onClick={mailToHandler}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaEnvelope />
          <p className={`san-serif ${classes.link_text}`}>Email</p>
        </Link>
      </div>
    </IconContext.Provider>
  );
};

export default Footer;
