import React from "react";
import classes from "./Button.module.css";
import { Link } from "react-router-dom";
const Button = (props) => {
  return (
    <Link to={props.href}>
      <button className={classes.button}>{props.text}</button>
    </Link>
  );
};

export default Button;
