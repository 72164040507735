import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import ProjectsPage from "./pages/Projects";
import AboutMePage from "./pages/AboutMe";
import ResumePage from "./pages/Resume";
import SortVisualization from "./pages/SortVisualization";
import AuthorizationExample from "./pages/AuthorizationExample";
import CartCheckout from "./pages/CartCheckout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <ProjectsPage /> },
      { path: "/aboutme", element: <AboutMePage /> },
      {
        path: "/resume",
        element: <ResumePage />,
      },
      { path: "/sortingvisualization", element: <SortVisualization /> },
      { path: "/authorizationexample", element: <AuthorizationExample /> },
      { path: "/cartcheckout", element: <CartCheckout /> },
    ],
  },
]);
const App = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export default App;
