import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import GlobalNav from "../components/GlobalNav";

const RootLayout = () => {
  return (
    <div id="page-container">
      <GlobalNav />
      <main id="page-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default RootLayout;
