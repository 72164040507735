import React from "react";
import Button from "./Button";

import classes from "./ProjectCard.module.css";

const ProjectCard = (props) => {
  return (
    <div className={classes.card_container}>
      <h2 className={`serif ${classes.card_title}`}>{props.title}</h2>
      <hr />
      <p className={`san-serif ${classes.card_discription}`}>
        {props.description}
      </p>
      <div className={classes.button_container}>
        <Button text="Go To Project" href={props.href}></Button>
      </div>
    </div>
  );
};

export default ProjectCard;
