import React from "react";
import classes from "./CartCheckout.module.css";
import { useEffect } from "react";

const CartCheckout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.page_container}>
        <h1 className={`serif ${classes.project_title}`}>Cart Checkout</h1>
        <p className={`san-serif ${classes.project_discription}`}>
          The cart checkout project is an example react app that shows how to
          use Redux for state management across large webpages. Redux is a state
          management system that is used to store and retrieve data and reduce
          the need for passing event handlers and data up/down the react
          component tree. In this project, I used Redux to store menu items for
          a fake burger shop. The user can add as many items as they want and
          the cart and cart object counter will be updated in real time. The
          user can add items on the menu items page and then checkout by
          clicking on the cart icon at the upper right-hand side of the
          navigation bar. Once the cart is open a user may reduce/increase the
          number of a specific items in the cart. If any item reaches zero it is
          removed from the cart menu. All items on checkout cart and menu page
          stay up to date with each other. While the user is in the checkout
          cart they have two options, to either hit the cancel button which
          exits the cart and keeps the state of the menu items or they can hit
          the confirm button and the cart is closed and the cart state is reset
          to the initial state. Confirming the order will also display a banner
          for ten seconds that states that the user has successfully placed an
          order. I used react to store all state in this project including
          storing/updating the cart items, resetting the cart items, showing the
          cart and success banner. It should be noted that it may be better to
          maximize the embedded editor to have a better view of the working
          project.
        </p>
        <iframe
          src="https://codesandbox.io/embed/github/BMRobbins/Cart-Checkout/main"
          style={{
            width: "100%",
            height: "1000px",
            border: "0",
            borderRadius: "4px",
            overflow: "hidden",
          }}
          allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
          title="Cart-Checkout"
        ></iframe>
      </div>
    </>
  );
};

export default CartCheckout;
