import React from "react";
import classes from "./SortVisualization.module.css";
import { useEffect } from "react";

const SortVisualization = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.page_container}>
        <h1 className={`serif ${classes.project_title}`}>Sort Visualization</h1>
        <p className={`san-serif ${classes.project_discription}`}>
          The sort visualization project is used to show real time animations of
          popular sorting algorithms. There is something visually satisfying
          about watching an unsorted array of integers become sorted with real
          time animations. I created four buttons that can be used to manipulate
          the list of integers. The first button “randomize array” is used to
          create a randomized array of integers or reset a sorted array. Each
          time a user clicks on this button a new array will be represented.
          Then the other buttons actually implement the sorting animations. I
          decided to implement the animations for Bubble Sort, Selection Sort
          and Merge Sort. It should be noted that it may be better to maximize
          the embedded editor to have a better view of the working project.
        </p>
        <p className={`san-serif ${classes.project_discription}`}>
          Let us first discuss what is going on in the Bubble Sort algorithm.
          Bubble Sort is an algorithm that iterates through a list of integers
          and compares two adjacent elements and if they are not in the correct
          order, we swap them. We repeat the process until all integers are in
          the correct order. This algorithm has a run time of O(n^2) average and
          worst-case scenario. With that being said on large data sets this
          would be a horrible sorting algorithm to use.
        </p>
        <p className={`san-serif ${classes.project_discription}`}>
          Now let us move to the next sorting algorithm called Selection Sort.
          In this algorithm we iterate through a list of integers and find the
          smallest integer and move it to the end of the sorted portion of the
          array. We continue this process until all integers are in order. This
          algorithm has a run time of O(n^2) average and worst-case scenario as
          well. With that being said on large data sets this would be a horrible
          sorting algorithm to use.
        </p>
        <p className={`san-serif ${classes.project_discription}`}>
          Finally let us discuss the last sorting algorithm called Merge Sort.
          In this algorithm we continuously divide the array into smaller and
          smaller arrays, and then merge them back together. The real work
          happens in the merge portion of the algorithm. Here we take the two
          groups of elements and compare each element from the left and right
          side and place them in order as we merge them together. This algorithm
          has a runtime of O(n log n) average and worst-case scenario. With that
          being said on large data sets this would be the best sorting algorithm
          to use as far as runtime efficiency is concerned. Another great choice
          would be Quick Sort, but I decided not to implement it in this
          project.
        </p>
        <p className={`san-serif ${classes.project_discription}`}>
          One thing I learned from this project was a bug I ran into when
          creating the animations. When iterating through a large array of
          animations and setting the setTimeout() method for each element, I
          needed to add a multiplier to each call for the setTimeout() method.
          If I didn’t, this would run all the animations at the same time making
          it seem as if it would be sorted instantly. This happens because the
          setTimeout() method is asynchronous which means all the calls could be
          ran out of sequence almost instantaneously. This turned out to be an
          easy fix but was a valuable lesson.
        </p>
        <iframe
          src="https://codesandbox.io/embed/github/BMRobbins/Sorting-Visualization/main"
          style={{
            width: "100%",
            height: "1000px",
            border: "0",
            borderRadius: "4px",
            overflow: "hidden",
          }}
          allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
          title="Sorting-Visualization"
        ></iframe>
      </div>
    </>
  );
};

export default SortVisualization;
