import classes from "./Resume.module.css";

const ResumePage = () => {
  return (
    <div className={classes.resume_container}>
      <div className={classes.title_container}>
        <h2 className={`serif ${classes.title}`}>BRIAN ROBBINS</h2>
        <hr />
      </div>
      <div>
        <div className={classes.link_section}>
          <div className={classes.link_section_row}>
            <p className={`san-serif ${classes.link_label}`}>Portfolio:</p>
            <a href="https://www.briansrobbins.com">
              https://www.briansrobbins.com
            </a>
          </div>
          <div className={classes.link_section_row}>
            <p className={`san-serif ${classes.link_label}`}>Github:</p>
            <a href="https://github.com/BMRobbins">
              https://github.com/BMRobbins
            </a>
          </div>
          <div className={classes.link_section_row}>
            <p className={`san-serif ${classes.link_label}`}>LinkedIn:</p>
            <a href="https://www.linkedin.com/in/brian-robbins-1a9253153/ ">
              https://www.linkedin.com/in/brian-robbins-1a9253153/
            </a>
          </div>
          <div className={classes.link_section_row}>
            <p className={`san-serif ${classes.link_label}`}>
              Phone: (714)469-5872
            </p>
          </div>
          <div className={classes.link_section_row}>
            <p className={`san-serif ${classes.link_label}`}>Email:</p>
            <a href="mailto:robbins.m.brian@gmail.com">
              robbins.m.brian@gmail.com
            </a>
          </div>
        </div>
        <div className={classes.pro_exp_container}>
          <h3 className={`serif ${classes.title}`}>PROFESSIONAL EXPERIENCE</h3>
          <div className={classes.job_container}>
            <div className={classes.company_container}>
              <h4 className={`serif  ${classes.company_name}`}>
                Quantum Research International
              </h4>
              <p className={`san-serif ${classes.ma_0}`}>
                April 2023 – Present
              </p>
            </div>
            <div className={classes.job_title_container}>
              <h5 className={`serif ${classes.job_title}`}>
                Full Stack Engineer
              </h5>
              <h5 className={`serif ${classes.job_title_tools}`}>
                (Javascript / Vue.js / React.js / Vite.js / C# / CSS / HTML)
              </h5>
            </div>
            <ul className={classes.project_ul}>
              <li className="san-serif">
                Created software solutions for Aerospace, Military, Navy and
                Army (DOD).
              </li>
              <li className="san-serif">
                Implemented web-based applications using Vue.js and React.js.
              </li>
              <li className="san-serif">
                Developed desktop applications using C# and the .Net framework.
              </li>
              <li className="san-serif">
                Built cross-platform mobile applications for iOS and Android
                using Dart and Flutter frameworks.
              </li>
              <li className="san-serif">
                Mentored junior developers, providing guidance and support
                throughout their professional development.
              </li>
              <li className="san-serif">
                Lead various team projects and assumed the responsibility of
                coordinating and delegating tasks.
              </li>
              <li className="san-serif">
                Implemented jest and vite unit tests to ensure bugs were caught
                early and reduce code regression.
              </li>
              <li className="san-serif">
                Collaborated with project managers and other engineers to make
                seamless user experiences.
              </li>
            </ul>
          </div>
          <div className={classes.job_container}>
            <div className={classes.company_container}>
              <h4 className={`serif  ${classes.company_name}`}>
                Chairish Inc.
              </h4>
              <p className={`san-serif ${classes.ma_0}`}>
                June 2021 – February 2023
              </p>
            </div>
            <div className={classes.job_title_container}>
              <h5 className={`serif ${classes.job_title}`}>
                UI Engineer / Full Stack Engineer
              </h5>
              <h5 className={`serif ${classes.job_title_tools}`}>
                (Javascript / Python / Django / CSS / HTML)
              </h5>
            </div>
            <ul className={classes.project_ul}>
              <li className="san-serif">
                Created paid seller plan structure and billing flow to increase
                revenue using Stripe.
              </li>
              <li className="san-serif">
                Made site wide search bar enhancements to increase user usage
                and completed orders.
              </li>
              <li className="san-serif">
                Implemented various A/B testing to make informed decisions for
                UI changes.
              </li>
              <li className="san-serif">
                Utilized product analytics software with in the site to track
                user usage data to make informed UI decisions.
              </li>
              <li className="san-serif">
                Designed reusable components that followed responsive web design
                practices.
              </li>
              <li className="san-serif">
                Upgraded legacy code to ES6 to move away from RequireJS to a
                newer framework.
              </li>
              <li className="san-serif">
                Implemented jest and python unit test to ensure bugs were caught
                early and reduce code regression.
              </li>
              <li className="san-serif">
                Collaborated with UX designers, project managers and other
                engineers to make seamless user experiences.
              </li>
            </ul>
          </div>
          <div className={classes.job_container}>
            <div className={classes.company_container}>
              <h4 className={`serif  ${classes.company_name}`}>
                Parasoft Corporation
              </h4>
              <p className={`san-serif ${classes.ma_0}`}>
                October 2018 – June 2021
              </p>
            </div>
            <div className={classes.job_title_container}>
              <h5 className={`serif ${classes.job_title}`}>
                Software Engineer
              </h5>
              <h5 className={`serif ${classes.job_title_tools}`}>(Java)</h5>
            </div>
            <ul className={classes.project_ul}>
              <li className="san-serif">
                Designed and developed software applications to improve
                functionality and usability of Parasoft software test products.
              </li>
              <li className="san-serif">
                Conducted research into software specific problems and devised
                solutions for solving them.
              </li>
              <li className="san-serif">
                Ensured new or updated releases were completed on time and on
                budget without compromise to quality.
              </li>
              <li className="san-serif">
                Developed API to upload testing tools to desktop or server-side
                testing instances.
              </li>
              <li className="san-serif">
                Created tools to monitor and virtualize endpoints for testing
                while endpoints are down.
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.projects_container}>
          <h3 className={`serif ${classes.title}`}>PROJECTS</h3>
          <div className={classes.project_name_container}>
            <h4 className={`serif ${classes.project_name}`}>
              PlantBasedTriathlete:
            </h4>
            <a
              href="https://github.com/BMRobbins/PlantBasedTriathlete"
              className={classes.project_link}
            >
              https://github.com/BMRobbins/PlantBasedTriathlete
            </a>
          </div>
          <h5 className={`serif ${classes.project_tools}`}>
            Developed in Javascript using React, Express.js, MongoDB, EJS, HTML
            and CSS
          </h5>
          <ul className={classes.ma_0}>
            <li className="san-serif">
              Created a site for client to blog and share recipes.
            </li>
            <li className="san-serif">
              All user’s login information is encrypted to protect sensitive
              information using bycrypt.
            </li>
            <li className="san-serif">
              Created custom portal to allow non-technical admin to upload blog
              posts, recipes and images.
            </li>
            <li className="san-serif">
              Used MongoDB to store blog posts, images and recipes information.
            </li>
            <li className="san-serif">
              Website endpoint routing uses Express.js to serve up embedded
              Javascript templates.
            </li>
          </ul>
          <div className={classes.project_name_container}>
            <h4 className={`serif ${classes.project_name}`}>Fabflix:</h4>
            <a
              href="https://github.com/BMRobbins/FabflixTeam24"
              className={classes.project_link}
            >
              https://github.com/BMRobbins/FabflixTeam24
            </a>
          </div>
          <h5 className={`serif ${classes.project_tools}`}>
            Developed in Java using Java Servlets, Javascript, MySql, CSS,
            Bootstrap and AWS services.
          </h5>
          <ul className={classes.ma_0}>
            <li className="san-serif">
              Site allows users to log into the site and browse through a
              database of movies and actors.
            </li>
            <li className="san-serif">
              Built using JSP and Java Servlets running on Apache Tomcat, hosted
              on an AWS EC2 instance.
            </li>
            <li className="san-serif">
              Keeps track of user’s session using user’s cookies.
            </li>
            <li className="san-serif">
              Allows users to check out and make purchases using a secure https
              connection.
            </li>
            <li className="san-serif">
              All user’s login information is encrypted to protect sensitive
              information.
            </li>
            <li className="san-serif">
              All forms use prepared statements to protect database from MySql
              injections.
            </li>
          </ul>
        </div>
        <div className={classes.education_container}>
          <h3 className={`serif ${classes.title}`}>EDUCATION</h3>
          <h4 className={`serif ${classes.university}`}>
            University of California, Irvine (UCI) – Irvine, CA
          </h4>
          <p className={`san-serif ${classes.u_par}`}>
            Bachelor of Science in Computer Science
          </p>
          <h4 className={`serif ${classes.related_work}`}>
            Relevant Course Work:
          </h4>
          <p className={`san-serif ${classes.u_par}`}>
            Python, C/C++, Java, Computer Organization, Computer Networking,
            Artificial Intelligence, Software Engineering, Databases, Data
            Structures, Algorithms, Machine Data Mining, Embedded Software,
            Human Computer Interaction, Principals in System Design and
            Technical Writing.
          </p>
        </div>
        <div className={classes.education_container}>
          <h3 className={`serif ${classes.title}`}>TECHNICAL SKILLS</h3>
          <h4 className={`serif ${classes.university}`}>
            Languages / Software:
          </h4>
          <p className={`san-serif ${classes.u_par}`}>
            JavaScript, TypeScript, React.js, Node.js, GraphQL, Redux, Python,
            Django, Java, C / C++, Bootstrap, Bash, MySql, MongoDB, Postgres and
            HTML/CSS
          </p>
          <h4 className={`serif ${classes.related_work}`}>Tools:</h4>
          <p className={`san-serif ${classes.u_par}`}>
            AWS Services, Visual Studio, Pycharm, Sublime, Eclipse IDE, Vi/Vim,
            Ubuntu Linux, Jenkins, JIRA, Git, Figma, Jtest and Jest.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResumePage;
