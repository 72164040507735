import classes from "./Projects.module.css";
import ProjectCard from "../components/ProjectCard";

const ProjectsPage = () => {
  return (
    <div className={classes.projects_container}>
      <h1 className={`serif ${classes.projects_title}`}>Projects</h1>
      <p className={`san-serif ${classes.projects_description}`}>
        Welcome to my project page. This is a page showing some of the projects
        I have been working on. Each project I explain in detail what I am
        trying to accomplish and some of the problems I may have run into and
        how I solved them.
      </p>
      <div className={classes.card_container}>
        <ProjectCard
          title={"Authorization Example"}
          description={
            "This is an example of a react app project requiring a user to log in to have access to functionality to the webpage."
          }
          href="/authorizationexample"
        />
        <ProjectCard
          title={"Sorting Visualization"}
          description={
            "This is a react app used to show real time visual animations of bubble sort, selection sort and merge sort."
          }
          href="/sortingvisualization"
        />
        <ProjectCard
          title={"Cart Checkout"}
          description={
            "This is react app that allows users to add products to a cart and checkout their order using Redux state management."
          }
          href="/cartcheckout"
        />
      </div>
    </div>
  );
};

export default ProjectsPage;
