import React from "react";
import classes from "./TestimonialCard.module.css";
import { FcBusinessman, FcBusinesswoman, FcManager } from "react-icons/fc";

const TestimonialCard = (props) => {
  const getIcon = (characterId) => {
    let iconElement = "";
    switch (characterId) {
      case 1:
        iconElement = <FcBusinessman />;
        break;
      case 2:
        iconElement = <FcBusinesswoman />;
        break;
      case 3:
        iconElement = <FcManager />;
        break;
      default:
        iconElement = <FcManager />;
    }
    return iconElement;
  };

  const characterIcon = getIcon(props.characterId);

  return (
    <div className={classes.card_container}>
      {characterIcon}
      <h2 className={`serif ${classes.card_title}`}>{props.title}</h2>
      <p className={`san-serif ${classes.card_discription}`}>
        "{props.description}"
      </p>
    </div>
  );
};

export default TestimonialCard;
